import React, { useEffect } from "react";
import { Card, Divider } from "antd";
import { PlusCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import "./pricing.css";

const Plan = (props) => {
  useEffect(() => {
    const el = document.createElement("script");
    el.onload = () => {
      var chargebeeInstance = window.Chargebee.init({
        site: "citymaas",
      });
      window.Chargebee.registerAgain();
      window.Chargebee.getInstance();
    };
    el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
    document.body.appendChild(el);

    console.log(props.pricingData);
  }, [props.pricingData]);

  return (
    <div className="card-div">
      {/* *******************************************************************************Card 1***************************************************************** */}
      {/* <Card className="basic-card">
        <span className="card-title">Assist Me Basic</span>
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "#014a63",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        <div>
          <span className="rate">
            {" "}
            {props.currency === "GBP"
              ? "£"
              : props.currency === "USD"
              ? "$"
              : "€"}{" "}
            {props.pricingData.length > 1 && props.pricingData[0].plan_price}
          </span>
          /
          <span className="time">
            {props.pricingData.length > 1 &&
            props.pricingData[1].duration === "1 year"
              ? "year"
              : "month"}
          </span>
        </div>
        <div className="features">
          <ul>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />{" "}
              Basic Plugin
            </li>
          </ul>
        </div>
        <a
          className="button-checkout"
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id={
            props.pricingData.length > 1 && props.pricingData[0].plan_id
          }
        >
          SUBSCRIBE
        </a>
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </Card> */}
      {/* *******************************************************************************Card 2***************************************************************** */}

      <Card className="basic-card">
        <span className="card-title">
          {props.pricingData.length > 1 && props.pricingData[0].plan_name}
        </span>
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "hotpink",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        {/* ***************************************************************Pricing Plus*************************************************************** */}

        <div>
          <span className="rate">
            {props.currency === "GBP"
              ? "£"
              : props.currency === "USD"
              ? "$"
              : "€"}{" "}
            {props.pricingData.length > 1 && props.pricingData[0].plan_price}
          </span>
          /
          <span className="time">
            {props.pricingData.length > 1 &&
            props.pricingData[0].duration === "1 year"
              ? "year"
              : "month"}
          </span>
        </div>
        <div>
          <h4>
            <strong>14 days free trial</strong>
          </h4>
        </div>
        <br />
        <a
          className="button-checkout"
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id={
            props.pricingData.length > 1 && props.pricingData[0].plan_id
          }
        >
          START FREE TRIAL
        </a>
        {/* *************************************************************** Features Plus *************************************************************** */}
        <div className="features">
          <ul>
            <h4>
              <strong>Content Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Emphasis
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Alignment
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text and Letter spacing
            </li>
            <br />
            <h4>
              <strong>Style Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Contrast and Saturation
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Colors
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monochrome mode
            </li>
            <br />
            <h4>
              <strong>Navigation Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Highlight focus/hover
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Screen reader and reading guide
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Mute sounds and stop animations
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Keyboard Navigation and Big mouse pointer
            </li>
            <br />
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Language Settings
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Disability Profile Selection
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Inbuilt Wikipedia Dictionary
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Load personalized UI preferences
            </li>{" "}
          </ul>
        </div>

        <div class="oceantwo">
          <div class="wavetwo"></div>
          <div class="wavetwo"></div>
        </div>
      </Card>
      {/* *******************************************************************************Card 3***************************************************************** */}

      <Card className="basic-card">
        <span className="card-title">
          {props.pricingData.length > 1 && props.pricingData[1].plan_name}
        </span>
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "#4a0196",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        {/* ***************************************************************Pricing Smart*************************************************************** */}

        <div>
          <span className="rate">
            {props.currency === "GBP"
              ? "£"
              : props.currency === "USD"
              ? "$"
              : "€"}{" "}
            {props.pricingData.length > 1 && props.pricingData[1].plan_price}
          </span>
          /
          <span className="time">
            {props.pricingData.length > 1 &&
            props.pricingData[1].duration === "1 year"
              ? "year"
              : "month"}
          </span>
        </div>
        <div>
          <h4>
            <strong>14 days free trial</strong>
          </h4>
        </div>
        <br />

        <a
          className="button-checkout"
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id={
            props.pricingData.length > 1 && props.pricingData[1].plan_id
          }
        >
          START FREE TRIAL
        </a>
        {/* ***************************************************************Features Smart*************************************************************** */}
        <div className="features">
          <ul>
            <h4>
              <strong>Content Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Emphasis
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Alignment
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text and Letter spacing
            </li>
            <br />
            <h4>
              <strong>Style Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Contrast and Saturation
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Colors
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monochrome mode
            </li>
            <br />
            <h4>
              <strong>Navigation Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Highlight focus/hover
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Screen reader and reading guide
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Mute sounds and stop animations
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Keyboard Navigation and Big mouse pointer
            </li>
            <br />
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Language Settings
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Disability Profile Selection
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Inbuilt Wikipedia Dictionary
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Load personalized UI preferences
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Assist me data analytics
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monthly Web Accessibility Audit Report - WCAG 2.1{" "}
            </li>
          </ul>
        </div>

        <div class="oceanthird">
          <div class="wavethird"></div>
          <div class="wavethird"></div>
        </div>
      </Card>
      {/* *******************************************************************************Card 4***************************************************************** */}

      <Card className="basic-card">
        <span className="card-title">
          {/* {props.pricingData.length > 1 && props.pricingData[3].plan_name} */}
          Assist Ultimate
        </span>{" "}
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "#ff6f00",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        {/* ***************************************************************Pricing Ultimate*************************************************************** */}
        <div>
          <span className="rate">Get a quote</span>
        </div>
        <div>
          <h4>
            <strong>14 days free trial</strong>
          </h4>
        </div>
        {/* ***************************************************************Features Ultimate*************************************************************** */}
        <br />
        <a
          className="button-checkout "
          href="mailto:hello@CityMaaS.io"
          // href="javascript:void(0)"
          // data-cb-type="checkout"
          // data-cb-plan-id={
          //   props.pricingData.length > 1 && props.pricingData[3].plan_id
          // }
        >
          CONTACT US
        </a>
        <div className="features">
          <ul>
            <h4>
              <strong>Content Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Emphasis
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Alignment
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text and Letter spacing
            </li>
            <br />
            <h4>
              <strong>Style Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Contrast and Saturation
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Colors
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monochrome mode
            </li>
            <br />
            <h4>
              <strong>Navigation Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Highlight focus/hover
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Screen reader and reading guide
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Mute sounds and stop animations
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Keyboard Navigation and Big mouse pointer
            </li>
            <br />
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Language Settings
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Disability Profile Selection
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Inbuilt Wikipedia Dictionary
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Load personalized UI preferences
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Assist me data analytics
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Weekly Web Accessibility Audit Report - WCAG 2.1{" "}
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Enterprise License
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Accessibility Statement
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Enterprise License
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Much more
            </li>
          </ul>
        </div>
        <div class="ocean4">
          <div class="wave4"></div>
          <div class="wave4"></div>
        </div>
      </Card>
    </div>
  );
};

export default Plan;
