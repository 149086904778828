import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Select, Button } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Notification from "../../CommonComponents/Notification/index";
import AssistSmartPlan from './AssistSmartPlan'
import "../assist-me.css";

const { Option } = Select;

const AssistSmart = (props) => {
  const [currency, setCurrency] = useState("GBP");
  const [duration, setDuration] = useState("Yearly");
  const [pricingData, setPricingData] = useState([]);
  const [arrayFinal, setFinal] = useState([]);
  const [shouldGo, setShouldGo] = useState(false);

  useEffect(() => {
    setShouldGo(false);
    props.onGetPricing(currency, (res) => {
      if (res && !res.status) {
        Notification(
          "error",
          res.message ? res.message : "Something went wrong"
        );
      } else {
        setPricingData(res.response.pricing);
        let packages = res.response.pricing;
        let final = [];
        if (currency === "GBP") {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_gbp") >= 0) {
                final.push(array);
              } else if (Object.values(array).indexOf("assist_plus_gbp") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_gbp") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_gbp") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_gbp") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_plus_gbp_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_gbp_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_gbp_monthly") >= 0
              ) {
                final.push(array);
              }
            });
          }
        } else if (currency === "USD") {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_usd") >= 0) {
                final.push(array);
              } else if (Object.values(array).indexOf("assist_plus_usd") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_usd") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_usd") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_usd") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_plus_usd_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_usd_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_usd_monthly") >= 0
              ) {
                final.push(array);
              }
            });
          }
        } else {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_eur") >= 0) {
                final.push(array);
              } else if (Object.values(array).indexOf("assist_plus_eur") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_eur") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_eur") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (Object.values(array).indexOf("freeplan_eur") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_plus_eur_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_smart_eur_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("assist_ultimate_eur_monthly") >= 0
              ) {
                final.push(array);
              }
            });
          }
        }
        final.sort((a, b) => (a.plan_price > b.plan_price ? 1 : -1));
        setFinal(final);
        setShouldGo(true);
      }
    });
  }, [currency, duration]);

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };
  return (
    <div className="pricing-div">
      <span className="header-line">Upgrade to Assist Smart</span>
      <span className="desc">
        Provide your customers our personalised accessible tool so ALL can buy
        your product and services
      </span>

      <div className="curr-dd">
        <span className="select-currency">
          <Select
            defaultValue="GBP"
            style={{ width: 120, color: "#252857" }}
            onChange={handleCurrencyChange}
          >
            <Option value="GBP">GBP</Option>
            <Option value="USD">USD</Option>
            <Option value="EUR">EUR</Option>
          </Select>
        </span>
      </div>

      <div className="curr-dd">
        <span className="select-currency">
          <Button
            className={
              duration === "Yearly" ? "currency-primary" : "currency-button"
            }
            onClick={() => handleDurationChange("Yearly")}
          >
            Yearly
          </Button>
          <Button
            className={
              duration === "Monthly" ? "currency-primary" : "currency-button"
            }
            onClick={() => handleDurationChange("Monthly")}
          >
            Monthly
          </Button>
        </span>
      </div>
      {shouldGo && arrayFinal && arrayFinal.length > 1 && (
        <AssistSmartPlan
          currency={currency}
          pricingData={arrayFinal}
          duration={duration}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});
const mapDispatchToProps = (dispatch) => {
  return {
    onGetPricing: (currency, res) =>
      dispatch(actions.getPricing(currency, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssistSmart)
);
