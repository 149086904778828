import React, { useEffect } from "react";
import { Card, Divider } from "antd";
import { PlusCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import "../pricing.css";

const AssistSmartPlan = (props) => {
  useEffect(() => {
    const el = document.createElement("script");
    el.onload = () => {
      var chargebeeInstance = window.Chargebee.init({
        site: "citymaas",
      });
      window.Chargebee.registerAgain();
      window.Chargebee.getInstance();
    };
    el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
    document.body.appendChild(el);

    console.log(props.pricingData);
  }, [props.pricingData]);

  return (
    <div className="card-div-single">
    {/* *******************************************************************************Card 3***************************************************************** */}

    <Card className="basic-card">
        <span className="card-title">
          {props.pricingData.length > 1 && props.pricingData[1].plan_name}
        </span>
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "#4a0196",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        {/* ***************************************************************Pricing Smart*************************************************************** */}

        <div>
          <span className="rate">
            {props.currency === "GBP"
              ? "£"
              : props.currency === "USD"
              ? "$"
              : "€"}{" "}
            {props.pricingData.length > 1 && props.pricingData[1].plan_price}
          </span>
           / 
          <span className="time">
            {props.pricingData.length > 1 &&
            props.pricingData[1].duration === "1 year"
              ? "year"
              : "month"}
          </span>
        </div>
        <br/>
        <a
          className="button-checkout"
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id={
            props.pricingData.length > 1 && props.pricingData[1].plan_id
          }
        >
         START A FREE TRIAL
        </a>
        {/* ***************************************************************Features Smart*************************************************************** */}
        <div className="features">
          <ul>
          <h4>
              <strong>Content Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Emphasis
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Alignment
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text and Letter spacing
            </li>
            <br />
            <h4>
              <strong>Style Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Contrast and Saturation
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Text Colors
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monochrome mode
            </li>
            <br />
            <h4>
              <strong>Navigation Adjustments</strong>
            </h4>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Highlight focus/hover
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Screen reader and reading guide
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Mute sounds and stop animations
            </li>
            <li>
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Keyboard Navigation and Big mouse pointer
            </li>
            <br />
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Language Settings
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Disability Profile Selection
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Inbuilt Wikipedia Dictionary
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Load personalized UI preferences
            </li>{" "}
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Assist me data analytics
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Monthly Web Accessibility Audit Report - WCAG 2.1{" "}
            </li>
            <li>
              <PlusCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              14 days free trial
            </li>
          </ul>
        </div>
     
        <div class="oceanthird">
          <div class="wavethird"></div>
          <div class="wavethird"></div>
        </div>
      </Card>
    </div>
  );
};

export default AssistSmartPlan;
