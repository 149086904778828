import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  getAllSubsLoading: false,
  getAllSubsSuccess: null,
  getAllSubsError: false,

  deleteSubLoading: false,
  deleteSubSuccess: null,
  deleteSubError: false,
};

//subscription reducer funtion on start
const getAllSubsStart = (state, action) => {
  return updateObject(state, {
    getAllSubsError: false,
    getAllSubsLoading: action.data,
  });
};

//subscription reducer funtion on success
const getAllSubsSuccess = (state, action) => {
  return updateObject(state, { getAllSubsSuccess: action.data });
};

//subscription reducer funtion on fail
const getAllSubsFail = (state, action) => {
  return updateObject(state, { getAllSubsError: action.error });
};

//subscription reducer funtion on start
const deleteSubStart = (state, action) => {
  return updateObject(state, {
    deleteSubError: false,
    deleteSubLoading: action.data,
  });
};

//subscription reducer funtion on success
const deleteSubSuccess = (state, action) => {
  return updateObject(state, { deleteSubSuccess: action.data });
};

//subscription reducer funtion on fail
const deleteSubFail = (state, action) => {
  return updateObject(state, { deleteSubError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_SUBS_START:
      return getAllSubsStart(state, action);
    case actionTypes.GET_ALL_SUBS_SUCCESS:
      return getAllSubsSuccess(state, action);
    case actionTypes.GET_ALL_SUBS_FAIL:
      return getAllSubsFail(state, action);

    case actionTypes.DELETE_SUBS_START:
      return deleteSubStart(state, action);
    case actionTypes.DELETE_SUBS_SUCCESS:
      return deleteSubSuccess(state, action);
    case actionTypes.DELETE_SUBS_FAIL:
      return deleteSubFail(state, action);

    default:
      return state;
  }
};

export default reducer;
