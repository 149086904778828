import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  signUpLoading: false,
  signUpSuccess: null,
  signUpError: false,

  signInSuccess: null,
  signInError: null,
  signInLoading: false,
  userId: sessionStorage.getItem("userId"),

  resetRequestLoading: false,
  resetRequestSuccess: null,
  resetRequestError: false,

  reserPasswordLoading: false,
  reserPasswordSuccess: null,
  reserPasswordError: false,
};

//signup reducer funtion on start
const signUpStart = (state, action) => {
  return updateObject(state, {
    signUpError: false,
    signUpLoading: action.data,
  });
};
//signup reducer funtion on success
const signUpSuccess = (state, action) => {
  return updateObject(state, {
    signUpSuccess: action.data,
    signUpLoading: false,
  });
};
//signup reducer funtion on failure
const signUpFail = (state, action) => {
  return updateObject(state, {
    signUpError: action.error,
    signUpLoading: false,
  });
};
//signin reducer funtion on start
const signInStart = (state, action) => {
  return updateObject(state, {
    signInError: false,
    signInLoading: action.data,
  });
};
//signin reducer funtion on success
const signInSuccess = (state, action) => {
  return updateObject(state, { signInSuccess: action.data.user_id });
};
//signin reducer funtion on failure
const signInFail = (state, action) => {
  return updateObject(state, { signInError: action.error });
};
//on signout
const signOut = (state, action) => {
  return updateObject(state, { signInSuccess: null, signInLoading: false });
};
//reset request reducer funtion on start
const resetRequestStart = (state, action) => {
  return updateObject(state, {
    resetRequestError: false,
    resetRequestLoading: true,
  });
};
//reset request reducer funtion on success
const resetRequestSuccess = (state, action) => {
  return updateObject(state, { resetRequestSuccess: action.data });
};
//reset request reducer funtion on failure
const resetRequestFail = (state, action) => {
  return updateObject(state, { resetRequestError: action.error });
};
//reset password reducer funtion on start
const reserPasswordStart = (state, action) => {
  return updateObject(state, {
    reserPasswordError: false,
    reserPasswordLoading: true,
  });
};
//reset password reducer funtion on success
const reserPasswordSuccess = (state, action) => {
  return updateObject(state, { reserPasswordSuccess: action.data });
};
//reset password reducer funtion on failure
const reserPasswordFail = (state, action) => {
  return updateObject(state, { reserPasswordError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_START:
      return signUpStart(state, action);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAIL:
      return signUpFail(state, action);

    case actionTypes.SIGN_IN_START:
      return signInStart(state, action);
    case actionTypes.SIGN_IN_SUCCESS:
      return signInSuccess(state, action);
    case actionTypes.SIGN_IN_FAIL:
      return signInFail(state, action);
    case actionTypes.SIGN_OUT:
      return signOut(state, action);

    case actionTypes.RESET_REQUEST_START:
      return resetRequestStart(state, action);
    case actionTypes.RESET_REQUEST_SUCCESS:
      return resetRequestSuccess(state, action);
    case actionTypes.RESET_REQUEST_FAIL:
      return resetRequestFail(state, action);

    case actionTypes.RESET_PASSWORD_START:
      return reserPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return reserPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return reserPasswordFail(state, action);

    default:
      return state;
  }
};

export default reducer;
