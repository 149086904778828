/*************************************************************AUTH ACTION TYPES *****************************************************************/
/**
 * Action types for sign up
 */
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";
/**
 * Action types for sign in/ sign out
 */
export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const SIGN_OUT = "SIGN_OUT";
/**
 * Action types for reset request
 */
export const RESET_REQUEST_START = "RESET_REQUEST_START";
export const RESET_REQUEST_SUCCESS = "RESET_REQUEST_SUCCESS";
export const RESET_REQUEST_FAIL = "RESET_REQUEST_FAIL";
/**
 * Action types for reset password
 */
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

/*************************************************************PAYMENT ACTION TYPES *****************************************************************/
/**
 * Action types for create payment subscription
 */
export const PAYMENT_SUBC_START = "PAYMENT_SUBC_START";
export const PAYMENT_SUBC_SUCCESS = "PAYMENT_SUBC_SUCCESS";
export const PAYMENT_SUBC_FAIL = "PAYMENT_SUBC_FAIL";
/**
 * Action types for create payment subscription
 */
export const GET_PRICING_START = "GET_PRICING_START";
export const GET_PRICING_SUCCESS = "GET_PRICING_SUCCESS";
export const GET_PRICING_FAIL = "GET_PRICING_FAIL";

/*************************************************************SUBSCRIPTION ACTION TYPES *****************************************************************/
/**
 * Action types for create payment subscription
 */
export const GET_ALL_SUBS_START = "GET_ALL_SUBS_START";
export const GET_ALL_SUBS_SUCCESS = "GET_ALL_SUBS_SUCCESS";
export const GET_ALL_SUBS_FAIL = "GET_ALL_SUBS_FAIL";
/**
 * Action types for create payment subscription
 */
export const DELETE_SUBS_START = "DELETE_SUBS_START";
export const DELETE_SUBS_SUCCESS = "DELETE_SUBS_SUCCESS";
export const DELETE_SUBS_FAIL = "DELETE_SUBS_FAIL";
