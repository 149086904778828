import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Notification from "../../CommonComponents/Notification/index";
import * as actions from "../../../store/actions/index";
import "./resetPassword.css";

const layout = {
  labelCol: {
    span: 4,
    offset: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

const ResetPassword = (props) => {
  const [assist_id, setAssist_id] = useState("");
  /**
   * on redirection from email
   * finds assist key in url
   */
  useEffect(() => {
    let assist_id = "";
    if (props.location && props.location.search) {
      assist_id = props.location.search;
      assist_id = assist_id.replace("?assistkey=", "");
      setAssist_id(assist_id);
    }
  }, [props, props.location]);
  /**
   *
   * when data is submitted
   */
  const onFinish = (values) => {
    const credential = { ...values, assist_id };
    props.onResetPassword(credential, (res) => {
      if (res && !res.status) {
        Notification(
          "error",
          res.message ? res.message : "Something went wrong"
        );
      } else {
        props.history.push("/");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="form-setup">
      <Card className="reset-pass-card">
        <div className="div-links">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password aria-label="Password" aria-required="true" />
            </Form.Item>
            <Form.Item className="div-button">
              <Button
                className="primary-button"
                htmlType="submit"
                id="action"
                loading={props.loading}
              >
                Submit
              </Button>
            </Form.Item>
            <div className="signin-link">
              Remember account? &nbsp;{" "}
              <Link to="/sign-in" aria-label="signin">
                Sign In!
              </Link>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};
//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.reserPasswordLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (credentials, res) =>
      dispatch(actions.resetPassword(credentials, res)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
