import React from "react";
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

import App from "./App";
import SignIn from "./Components/UserManagement/SignIn/SignIn";
import ResetPassword from "./Components/UserManagement/ResetPassword/ResetPassword";
import ResetRequest from "./Components/UserManagement/ResetRequest/ResetRequest";
import Logout from "./Components/UserManagement/Logout/Logout";
import AssistUltimate from "./Components/AssistMe/AssistMeUltimate/AssistUltimate";
import AssistSmart from "./Components/AssistMe/AssistMeSmart/AssistSmart";
import AssistPlus from "./Components/AssistMe/AssistMePlus/AssistPlus";
import SponsorPackages from "./Components/Sponsors/SponsorPackages/SponsorPackages";
import BusinessPackages from "./Components/Sponsors/BusinessPackages/BusinessPackages";

const Routes = (props) => {
  //routes within layout goes in app
  let routes = "";
  if (props.isAuthenticated || props.isAuthData) {
    routes = (
      <Switch>
        <Route
          exact
          path="/sponsor-partnerships"
          render={({ match }) => <SponsorPackages match={match} />}
        />{" "}
        <Route
          exact
          path="/business-partnerships"
          render={({ match }) => <BusinessPackages match={match} />}
        />
        <Route
          exact
          path="/assist-ultimate"
          render={({ match }) => <AssistUltimate match={match} />}
        />
        <Route
          exact
          path="/assist-smart"
          render={({ match }) => <AssistSmart match={match} />}
        />
        <Route
          exact
          path="/assist-plus"
          render={({ match }) => <AssistPlus match={match} />}
        />
        <Route exact path="/logout" component={Logout} />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/reset-request" component={ResetRequest} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/" component={App} />
        <Redirect to="/sign-in" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route
          exact
          path="/sponsor-partnerships"
          render={({ match }) => <SponsorPackages match={match} />}
        />{" "}
        <Route
          exact
          path="/business-partnerships"
          render={({ match }) => <BusinessPackages match={match} />}
        />
        <Route
          exact
          path="/assist-ultimate"
          render={({ match }) => <AssistUltimate match={match} />}
        />
        <Route
          exact
          path="/assist-smart"
          render={({ match }) => <AssistSmart match={match} />}
        />
        <Route
          exact
          path="/assist-plus"
          render={({ match }) => <AssistPlus match={match} />}
        />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/reset-request" component={ResetRequest} />
        <Route path="/reset-password" component={ResetRequest} />
        <Route path="/" component={App} />
        <Redirect to="/" />
      </Switch>
    );
  }
  return <Router>{routes}</Router>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});

export default withRouter(connect(mapStateToProps, null)(Routes));
