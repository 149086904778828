import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import { Button, Collapse } from "antd";
import install1 from "../../assets/install1.svg";
import wix from "../../assets/wix.svg";
import wp from "../../assets/wp.svg";

import "./confirmation.css";

const { Panel } = Collapse;
const text = (
  <div className="ins padd">
    <span>
      Log in to your website builder and paste the code within the footers menu
      of your website, or place it just before the closing body tag.
    </span>
    <span>
      If your developer or webmaster is installing the code, sned these
      instructions to them and they will know what to do!
    </span>
  </div>
);

const Confirmation = (props) => {
  const [name, setname] = useState('')
  const [subid, setsubid] = useState('')
  const [email, setemail] = useState('')
  useEffect(() => {
    let serachStr = '';

    if(props.location.search){
      serachStr = props.location.search;
      let [name, subid, email] = serachStr.split('&');
      setname(name.replace('?name=', ''));
      setsubid(subid.replace('sub_id=',''));
      setemail(email.replace('email=',''));
    }
  }, [])


  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  return (
    <div className="parent-div">
      <div className="ins">
        <span>Thanks {name} for signing up</span>
        {email ? <span> Here is your installation script. We have sent you an email with
          details to {email}</span> : <span>
          Here is your installation script. We have sent you an email with
          details.
        </span>}
      </div>

      <div className="script-link">
        <div className="inner-div">
          <div className="button-div">
            <Button className="button-copy" onClick={() => copyToClipboard("<script src='https://cdn.citymaas.io/frontendicons/assist-me.js'></script>")}>Copy</Button>
          </div>
          <div className="innest-div">
            <div>
              {" "}
              <img src={install1} alt="script" className='sc'/>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Collapse ghost>
          <Panel header="Show me how" key="1" className="collapse">
            <p>{text}</p>
          </Panel>
        </Collapse>
      </div>

      <div className="wix-wp-ins">
        <h5>Or install one of our pre-configured plugins:</h5>
        <div className="wp-div">
          <div className='mini-div'>
           <a href='https://wordpress.org/plugins/assist-me/' alt='link'><img src={wp} alt="wp" className="wp"/></a> 
            <img src={wix} alt="wix" className="wx" onClick={() => props.history.push('/install/wix')} />
          </div>
        </div>
        <h5>Complete registration now to manage your account.</h5>
      </div>
    </div>
  );
};

export default withRouter(Confirmation);
