import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import Sponsors from "./Components/Sponsors/Sponsors";
import AssistMe from "./Components/AssistMe/AssistMe";
import Topbar from "./Components/Layout/Topbar";
import Confirmation from "./Components/Confirm/Confirmation.js";
// import PluginSteps from './Components/AssistMe/PluginSteps';

import "./App.css";
import LandingPage from "./Components/LandingPage/LandingPage";
import Wix from "./Components/Confirm/Wix";

// import Subscriptions from './Components/AssistMe/Subscriptions/Subscriptions';
// import PackageSelection from './Components/AssistMe/PackageSelection';
// import SponsorSteps from './Components/Sponsors/SponsorSteps';
// import SponsorsPackageSelection from './Components/Sponsors/SponsorsPackageSelection';

const { Content, Footer } = Layout;
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //routes for authenticated users
    let route = "";
    if (this.props.isAuthenticated && sessionStorage.getItem("signin")) {
      route = (
        <Switch>
          <Route
            exact
            path="/assist-me"
            render={({ match }) => <AssistMe match={match} />}
          />
          <Route
            exact
            path="/sponsors"
            render={({ match }) => <Sponsors match={match} />}
          />
          <Route
            exact
            path="/install"
            render={({ match }) => <Confirmation match={match} />}
          />
          <Route
            exact
            path="/install/wix"
            render={({ match }) => <Wix match={match} />}
          />
        </Switch>
      );
    } else {
      route = (
        <Switch>
          <Route
            exact
            path="/install"
            render={({ match }) => <Confirmation match={match} />}
          />
          <Route
            exact
            path="/install/wix"
            render={({ match }) => <Wix match={match} />}
          />
          <Route
            exact
            path="/assist-me"
            render={({ match }) => <AssistMe match={match} />}
          />
          <Route
            exact
            path="/sponsors"
            render={({ match }) => <Sponsors match={match} />}
          />
         
          <Route
            exact
            path="/"
            render={({ match }) => <LandingPage match={match} />}
          />
        </Switch>
      );
    }

    return (
      <Layout className="App-header">
        <Topbar />
        <Content
          className="site-layout-background"
          style={{
            padding: "20px 10px",
            minHeight: 280,
          }}
        >
          {route}
        </Content>
        <Footer className="footer">
          <div className="footer-div">
            <p className="footer-text">©CityMaaS Ltd 2021</p>
          </div>
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});

export default withRouter(connect(mapStateToProps, null)(App));
