import { combineReducers } from "redux";
import authReducer from "./auth";
import paymentReducer from "./payment";
import subscriptionReducer from "./subscription";

const rootReducer = combineReducers({
  authReducer,
  paymentReducer,
  subscriptionReducer,
});

export default rootReducer;
