import React, { useEffect } from "react";
import { Form, Input, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Notification from "../../CommonComponents/Notification/index";
import "./signin.css";

const layout = {
  labelCol: {
    span: 4,
    offset: 2,
  },
  wrapperCol: {
    span: 14,
  },
};

const SignIn = (props) => {
  /**
   * on first time sign in user will get redirected from email
   * finds assist key in url
   */
  useEffect(() => {
    let assist_id = "";
    if (props.location && props.location.search) {
      assist_id = props.location.search;
      assist_id = assist_id.replace("?assistkey=", "");
      const credentials = {
        password: "Password@123",
        email: "email@email.com",
        mode: "email",
        assist_id,
      };
      /**
       * api call on sign in
       */
      props.onSignIn(credentials, (res) => {
        if (res && !res.status) {
          Notification(
            "error",
            res.message ? res.message : "Something went wrong"
          );
        } else {
          props.history.push("/sponsors");
        }
      });
    }
  }, []);
  /**
   * when data is submitted
   */
  const onFinish = (values) => {
    const credentials = { ...values, mode: "user", assist_id: "" };
    props.onSignIn(credentials, (res) => {
      if (res && !res.status) {
        Notification(
          "error",
          res.message ? res.message : "Something went wrong"
        );
      } else {
        props.history.push("/sponsors");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="form-setup">
      <Card className="sign-in-card">
        <div className="div-links">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input aria-label="email" aria-required="true" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password aria-label="Password" aria-required="true" />
            </Form.Item>
            <Form.Item className="div-button">
              <Button
                className="primary-button"
                htmlType="submit"
                id="action"
                loading={props.loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          <div className="signup-link">
            Don't have an account? &nbsp;{" "}
            <Link to="/assist-me/registration" aria-label="sign up">
              Sign Up!
            </Link>
          </div>
          <div className="signup-link">
            Forgot Password? &nbsp;{" "}
            <Link to="/reset-request" aria-label="reset">
              Reset
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.signInLoading,
    error: state.authReducer.signInError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (credentials, res) => dispatch(actions.signIn(credentials, res)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
