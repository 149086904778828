import React, { useEffect } from "react";
import { Card, Divider } from "antd";
import { PlusCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import "../packages.css";
const SponsorPackagesPlan = (props) => {
  useEffect(() => {
    const el = document.createElement("script");
    el.onload = () => {
      var chargebeeInstance = window.Chargebee.init({
        site: "citymaas",
      });
      window.Chargebee.registerAgain();
      window.Chargebee.getInstance();
    };
    el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
    document.body.appendChild(el);

    console.log(props.pricingData);
  }, [props.pricingData]);

  return (
    <div className="card-div-single">
      {/* *******************************************************************************Card 1***************************************************************** */}
      <Card className="packages-card">
        <span className="card-title">
          {" "}
          {props.pricingData.length > 1 && props.pricingData[0].plan_name}
        </span>
        <div className="divder-div">
          <Divider
            orientation="center"
            style={{
              background: "#014a63",
              minWidth: "20%",
              width: "20px",
              alignSelf: "center",
              height: 3,
              borderRadius: 12,
            }}
          />
        </div>
        {/* ***************************************************************Pricing basic*************************************************************** */}
        <div>
          <span className="rate">
            {props.currency === "GBP"
              ? "£"
              : props.currency === "USD"
              ? "$"
              : "€"}{" "}
            {props.pricingData.length > 1 && props.pricingData[0].plan_price}
          </span>
          /
          <span className="time">
            {props.pricingData.length > 1 && props.pricingData[0].duration}
          </span>
        </div>
        <br/>
        <a
          className="packages-button-checkout"
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id={
            props.pricingData.length > 1 && props.pricingData[0].plan_id
          }
        >
          Subscribe
        </a>
        {/* ***************************************************************Feature basic*************************************************************** */}
        <div className="features">
          <ul>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              AWARE Auto
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Assist me Smart
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Content Creation
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Social Media Posts
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Meetings
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Reports (Monthly)
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Included Hours 15
            </li>
            <li className="packages-list">
              <CheckCircleTwoTone
                twoToneColor="#03af58"
                style={{ marginRight: 6 }}
              />
              Set Up 7 Days
            </li>
          </ul>
        </div>
     
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </Card>
    </div>
  );
};

export default SponsorPackagesPlan;
