import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  paymentSubcLoading: false,
  paymentSubcSuccess: null,
  paymentSubcError: false,

  getPricingLoading: false,
  getPricingSuccess: [],
  getPricingError: false,
};

//payment reducer funtion on start
const paymentSubcStart = (state, action) => {
  return updateObject(state, {
    paymentSubcError: false,
    paymentSubcLoading: action.data,
  });
};
//payment reducer funtion on success
const paymentSubcSuccess = (state, action) => {
  return updateObject(state, { paymentSubcSuccess: action.data });
};
//payment reducer funtion on fail
const paymentSubcFail = (state, action) => {
  return updateObject(state, { paymentSubcError: action.error });
};

//payment reducer funtion on start
const getPricingStart = (state, action) => {
  return updateObject(state, {
    getPricingError: false,
    getPricingLoading: action.data,
  });
};
//payment reducer funtion on success
const getPricingSuccess = (state, action) => {
  return updateObject(state, { getPricingSuccess: action.data && action.data.response });
};
//payment reducer funtion on fail
const getPricingFail = (state, action) => {
  return updateObject(state, { getPricingError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_SUBC_START:
      return paymentSubcStart(state, action);
    case actionTypes.PAYMENT_SUBC_SUCCESS:
      return paymentSubcSuccess(state, action);
    case actionTypes.PAYMENT_SUBC_FAIL:
      return paymentSubcFail(state, action);

    case actionTypes.GET_PRICING_START:
      return getPricingStart(state, action);
    case actionTypes.GET_PRICING_SUCCESS:
      return getPricingSuccess(state, action);
    case actionTypes.GET_PRICING_FAIL:
      return getPricingFail(state, action);

    default:
      return state;
  }
};

export default reducer;
