import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import logo from "../../assets/Asset3.png";
const { Header } = Layout;
const { SubMenu } = Menu;

class Topbar extends Component {
  state = {
    current: "",
    openKey: "",
  };

  render() {
    const { current, openKey } = this.state;

    return (
      <div>
        <Header
          style={{
            position: "fixed",
            zIndex: 10,
            width: "100%",
            background: "white",
            height: 65,
            fontFamily: "vagRund",
            boxShadow:'5px rgba(0,0,0,0.7)'
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>

         
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={[current.toString()]}
                selectedKeys={[current.toString()]}
                defaultOpenKeys={[openKey]}
              >
                
                {window.screen.width > 600 && (
                 <a href="https://www.citymaas.io/"> <img src={logo} height={40} alt="logo" style={{marginTop: -5}}/></a>
                )}
                
                <Menu.Item key="sponsors" style={{marginLeft: window.screen.width > 600 ? 105 : 0}}>
                  <Link to="/sponsors">Partnership</Link>
                </Menu.Item>
                <Menu.Item key="assist-me" style={{marginLeft:window.screen.width > 600 ? 25 : 0}}>
                  <Link to="/assist-me">Assist Me</Link>
                </Menu.Item>
                <Menu.Item key="installation" style={{marginLeft:window.screen.width > 600 ? 25 : 0}}>
                  <Link to="/install">Installation</Link>
                </Menu.Item>
              </Menu>
            </div>
            <div>
              {sessionStorage.getItem("signin") === "true" ? (
                <Menu theme="light" mode="horizontal">
                  <SubMenu
                    key="sub1"
                    // icon={<MailOutlined />}
                    title="My Profile"

                  >
                    <Menu.Item key="subscriptions">
                      <Link to="/subscriptions"></Link>My Subscriptions
                    </Menu.Item>

                    <Menu.Item key="log">
                      <Link to="/logout">Logout</Link>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              ) : (
                    
                    <Menu theme="light" mode="horizontal">
                  <Menu.Item key="4">
                    <a href="javascript:void(0)" data-cb-type="portal">
                      Manage account
                    </a>
                  </Menu.Item>
                </Menu>
              )}
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});

export default withRouter(connect(mapStateToProps, null)(Topbar));
