import notification from './Notification';

const Notification = (type, message, description, placement) => {
    notification[type]({
        message,
        description,
        placement
    });
};
export default Notification;
