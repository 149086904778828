import React from "react";
import { Form, Input, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Notification from "../../CommonComponents/Notification/index";

import "./resetRequest.css";

const layout = {
  labelCol: {
    span: 4,
    offset: 2,
  },
  wrapperCol: {
    span: 12,
  },
};
const ResetRequest = (props) => {
  /**
   *
   * when data is submitted
   */
  const onFinish = (values) => {
    props.onResetRequest(values, (res) => {
      if (res && !res.status) {
        Notification(
          "error",
          res.message ? res.message : "Something went wrong"
        );
      } else {
        props.history.push("/");
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="form-setup">
      <Card className="reset-req-card">
        <div className="div-links">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input aria-label="email" aria-required="true" />
            </Form.Item>
            <Form.Item className="div-button">
              <Button
                className="primary-button"
                htmlType="submit"
                id="action"
                loading={props.loading}
              >
                Submit
              </Button>
            </Form.Item>
            <div className="signin-link">
              Remember account? &nbsp;{" "}
              <Link to="/sign-in" aria-label="signin">
                Sign In!
              </Link>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

//these state are from store to handle response
const mapStateToProps = (state) => {
  return {
    loading: state.authReducer.resetRequestLoading,
    error: state.authReducer.resetRequestError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onResetRequest: (credentials, res) =>
      dispatch(actions.resetRequest(credentials, res)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetRequest);
