import React from "react";
import { Form, Input, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./LandingPage.css";

const LandingPage = (props) => {
  return (
    <div className="land-setup">
      <Card className="land-card">
        <div className="land-links">
          <Button className='button-primary' onClick={()=> props.history.push('/sponsors')}>Partnerships</Button>
          <Button className='button-primary' onClick={()=> props.history.push('/assist-me')}>Assist me</Button>
        </div>
      </Card>
    </div>
  );
};

export default withRouter(LandingPage);
