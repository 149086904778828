import * as actionTypes from "./actionTypes";
import axios from "axios";
import config from "../../config";

/**
 *
 * {paymentSubcStart} job execution process starts
 */
export const paymentSubcStart = (data) => {
  return {
    type: actionTypes.PAYMENT_SUBC_START,
    data: data,
  };
};
/**
 *
 * {paymentSubcSuccess} job execution completes succesfully
 */
export const paymentSubcSuccess = (data) => {
  return {
    type: actionTypes.PAYMENT_SUBC_SUCCESS,
    data: data,
  };
};
/**
 *
 * {paymentSubcFail} job execution completes fails
 */
export const paymentSubcFail = (error) => {
  return {
    type: actionTypes.PAYMENT_SUBC_FAIL,
    error: error,
  };
};
/**
 *
 * {paymentSubc} job execution function call on button press
 */
export const paymentSubc = (obj, callback) => {
  return (dispatch) => {
    dispatch(paymentSubcStart(true));
    axios
      .post(`${config.adminApi}subscribe`, obj)
      .then((response) => {
        dispatch(paymentSubcStart(true));
        dispatch(paymentSubcSuccess({ status: true, response: response.data }));
        dispatch(paymentSubcStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(paymentSubcFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(paymentSubcStart(false));
      });
  };
};


/**
 *
 * {getPricingStart} job execution process starts
 */
export const getPricingStart = (data) => {
  return {
    type: actionTypes.GET_PRICING_START,
    data: data,
  };
};
/**
 *
 * {getPricingSuccess} job execution completes succesfully
 */
export const getPricingSuccess = (data) => {
  return {
    type: actionTypes.GET_PRICING_SUCCESS,
    data: data,
  };
};
/**
 *
 * {getPricingFail} job execution completes fails
 */
export const getPricingFail = (error) => {
  return {
    type: actionTypes.GET_PRICING_FAIL,
    error: error,
  };
};
/**
 *
 * {getPricing} job execution function call on button press
 */
export const getPricing = (currency, callback) => {
  return (dispatch) => {
    dispatch(getPricingStart(true));
    axios
      .get(`${config.adminApi}pricing?currency=${currency}`)
      .then((response) => {
        sessionStorage.setItem("currency", currency);
        dispatch(getPricingStart(true));
        dispatch(getPricingSuccess({ status: true, response: response.data }));
        dispatch(getPricingStart(false));
        callback({ status: true, response: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPricingFail(err));
        callback({
          status: false,
          message: err && err.response && err.response.data,
        });
        dispatch(getPricingStart(false));
      });
  };
};
