import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Select, Button } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Notification from "../../CommonComponents/Notification/index";
import SponsorPackagesPlan from "./SponsorPackagesPlan";
import "../sponsors.css";

const { Option } = Select;
const SponsorPackages = (props) => {
  const [currency, setCurrency] = useState("GBP");
  const [duration, setDuration] = useState("Monthly");
  const [arrayFinal, setFinal] = useState([]);
  const [shouldGo, setShouldGo] = useState(false);
  const [pricingData, setPricingData] = useState([]);

  // useEffect(async () => {
  //   await fetch("https://extreme-ip-lookup.com/json/")
  //     .then((res) => res.json())
  //     .then((response) => {
  //       // console.log("Country: ", response.country);
  //       if (response.continent === "Europe") {
  //         setCurrency("EUR");
  //       } else if (response.continent === "North America") {
  //         setCurrency("USD");
  //       } else {
  //         setCurrency("GBP");
  //       }
  //     })
  //     .catch((data, status) => {
  //       // console.log("Request failed");
  //     });
  // }, []);
  
  useEffect(() => {
    setShouldGo(false);

    props.onGetPricing(currency, (res) => {
      if (res && !res.status) {
        Notification(
          "error",
          res.message ? res.message : "Something went wrong"
        );
      } else {
        setPricingData(res.response.pricing);
        let packages = res.response.pricing;
        let final = [];
        if (currency === "GBP") {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("sponsor_package_gbp") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_gbp") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (
                Object.values(array).indexOf("sponsor_package_gbp_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_gbp_monthly") >=
                0
              ) {
                final.push(array);
              }
            });
          }
        } else if (currency === "USD") {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("sponsor_package_usd") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_usd") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (
                Object.values(array).indexOf("sponsor_package_usd_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_usd_monthly") >=
                0
              ) {
                final.push(array);
              }
            });
          }
        } else {
          if (duration === "Yearly") {
            packages.map((array) => {
              if (Object.values(array).indexOf("sponsor_package_eur") >= 0) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_eur") >= 0
              ) {
                final.push(array);
              }
            });
          } else {
            packages.map((array) => {
              if (
                Object.values(array).indexOf("sponsor_package_eur_monthly") >= 0
              ) {
                final.push(array);
              } else if (
                Object.values(array).indexOf("business_package_eur_monthly") >=
                0
              ) {
                final.push(array);
              }
            });
          }
        }
        final.sort((a, b) => (a.plan_price > b.plan_price ? 1 : -1));
        setFinal(final);
        setShouldGo(true);
      }
    });
  }, [currency, duration]);

  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };

  return (
    <div className="pricing-div">
      <span className="header-line">CityMaaS Partner Packages</span>
      <span className="desc">
        CityMaaS has three different packages where the CityMaaS team are able
        to take your business forward with accessibility audits, marketing,
        social media and much more.{" "}
      </span>

      <div className="curr-dd">
        <span className="select-currency">
          <Select
            defaultValue="GBP"
            style={{ width: 120, color: "#252857" }}
            onChange={handleCurrencyChange}
          >
            <Option value="GBP">GBP</Option>
            <Option value="USD">USD</Option>
            <Option value="EUR">EUR</Option>
          </Select>
        </span>
      </div>
      <div className="curr-dd">
        <span className="select-currency">
          <Button
            className={
              duration === "Yearly" ? "currency-primary" : "currency-button"
            }
            onClick={() => handleDurationChange("Yearly")}
          >
            Yearly
          </Button>
          <Button
            className={
              duration === "Monthly" ? "currency-primary" : "currency-button"
            }
            onClick={() => handleDurationChange("Monthly")}
          >
            Monthly
          </Button>
        </span>
      </div>
      {shouldGo && arrayFinal && arrayFinal.length > 1 && (
        <SponsorPackagesPlan
          currency={currency}
          pricingData={arrayFinal}
          duration={duration}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.userId !== null,
  isAuthData: state.authReducer.signInSuccess !== null,
});
const mapDispatchToProps = (dispatch) => {
  return {
    onGetPricing: (currency, res) =>
      dispatch(actions.getPricing(currency, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SponsorPackages)
);
