import React from "react";
import wixLogo from "../../assets/wixLogo.svg";
import step1 from "../../assets/1.png";
import step2 from "../../assets/2.png";
import step3 from "../../assets/3.png";
import step4 from "../../assets/4.png";
import step5 from "../../assets/5.png";

import "./wix.css";

const Wix = () => {
  return (
    <div className="parent-div">
      <div className="header-div">
        <img src={wixLogo} className="logo-wx" />
        <h1>Assist Me Manual Installation guide for Wix</h1>
      </div>

      <div className="inner-hed-div">
        <h4>
          To install Assist Me by CityMaaS on your Wix website, follow the
          step-by-step instructions below:
        </h4>
      </div>

      <div className="step-img-text">
        <div className="num-img">
          <h3>1</h3>
          <img src={step1} className="step1" />
        </div>
        <div className="step-text">
          <p>
            In your Wix dashboard (https://manage.wix.com/), click on Settings
            in the left hand pane.
          </p>
        </div>
      </div>

      <div className="step-img-text">
        <div className="num-img">
          {" "}
          <h3>2</h3>
          <img src={step2} className="step2" />
        </div>
        <div className="step-text">
          <p>
            In the Settings screen, scroll down to the Advanced section and
            click on Custom code
          </p>
        </div>
      </div>

      <div className="step-img-text">
        <div className="num-img">
          {" "}
          <h3>3</h3>
          <img src={step3} className="step3" />
        </div>
        <div className="step-text">
          <p>
            In the top right of the Custom Code screen, click on Add Custom Code
          </p>
        </div>
      </div>

      <div className="step-img-text">
        <div className="num-img">
          <h3>4</h3>
          <img src={step4} className="step4" />
        </div>
        <div className="step-text">
          <p>Complete the subsequent form exactly as in the picture</p>
        </div>
      </div>

      <div className="step-img-text">
        <div className="num-img">
          {" "}
          <h3>5</h3>
          <img src={step5} className="step5" />
        </div>

        <div className="step-text">
          <p>
            Select the Code Type tab at the top and complete the subsequent form
            exactly as in the picture
          </p>
        </div>
      </div>

      <span>
        If you have any issues please email us at{" "}
        <a href="mailto:support@CityMaaS.io">support@CityMaaS.io</a>.
      </span>
    </div>
  );
};

export default Wix;
